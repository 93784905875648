<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card v-if="uc != null && area != null">
        <v-toolbar :color="hexTints(area.cor,0.95)">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="ms-1 ps-0 fs-13pt f-lato fw-600">Editar / Atualizar Ementa</v-toolbar-title>
          <div class="ms-5 me-0 pe-0">
            <v-card class="pb-1 ps-1 pe-2 text-center" :color="hexTints(area.cor,0.85)">
              <i v-i:duo#graduation-cap#30 class="ms-1" v-cHex="area.cor"></i>
            </v-card>
          </div>
          <div class="ms-1">
            <p class="m-0 p-0 ms-1 f-opensans fw-500 fsp-n1 fs-12pt line-height-2 text-truncate">
              <span class="me-1 fw-700 f-sanspro fs-12pt">UC:</span>
              {{uc.nome}}
            </p>
            <p class="m-0 p-0 ms-1 f-sanspro fw-300 fs-11pt line-height-3">
              <span v-for="(area, key, index) in uc.areas" :key="refresh+key">
                <tagarea class="me-3" :sigla="area.sigla"/>
              </span>
            </p>
          </div>
          <div class="ms-5">
            <verplanoensino :uc="uc" :area="area" :key="refresh"/>
          </div>
        </v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="4">
              <ementaview :area="area" :ucSel="uc" :noedit="true"/>
            </v-col>
            <v-col cols="12" md="3">
              <conteudosview :uc="uc" :area="area" />
            </v-col>
            <v-col cols="12" md="5">
              <adicionarconteudos :area="area" :uc="uc" :key="uc.id+refresh" />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <parseementa ref="parseementaref" :area="area" :uc="uc"/>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import dummy from "@/components/dummy/dummy.vue"
import readmore from "@/components/readmore.vue"

export default {
  name: "ementaEdit",
  components: {
    dummy, readmore,
    'tagarea': () => import('@/views/cursos/tagArea.vue'),
    'ementaview': () => import('@/views/cursos/planosv2/ementa/ementaView.vue'),
    'adicionarconteudos': () => import('@/views/cursos/planosv2/ementa/adicionar-conteudos.vue'),
    'conteudosview': () => import('./conteudos-view.vue'),
    'parseementa': () => import('./parse-ementa.vue'),
    'verplanoensino': () => import('@/views/cursos/planosv2/ver-plano-ensino.vue'),
  },
  props: {
    area: { default: null, type: Object }
  },
  computed: {
  },
  data(){
    return {
      dialog: false,
      uc: null,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
  },
  methods:{

    build() {
      var self = this;
    },

    show(uc) {
      var self = this;
      console.log(uc);
      self.uc = uc;
      this.dialog = true;
      self.refresh++;
    },

    parse() {
      console.log("parse");
      console.log(this.uc.ementa);

    }
  }
}
</script>

<style scoped>

</style>
